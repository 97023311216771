import { FactionBE, FactionRE } from "@/game-eft/constants/constants.mjs";
import BEARIcon from "@/inline-assets/eft-faction-bear.svg";
import SCAVIcon from "@/inline-assets/eft-faction-scav.svg";
import USECIcon from "@/inline-assets/eft-faction-usec.svg";

export function getFactionIcon(
  faction: FactionBE | FactionRE,
): SVGComponent | undefined {
  switch (faction) {
    case FactionBE.BEAR:
    case FactionRE.bear: {
      return BEARIcon;
    }
    case FactionBE.SCAV:
    case FactionRE.savage: {
      return SCAVIcon;
    }
    case FactionBE.USEC:
    case FactionRE.usec: {
      return USECIcon;
    }
    default:
      return undefined;
  }
}
