import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Select } from "clutch/src/Select/Select.jsx";
import { mobile } from "clutch/src/Style/style.mjs";
import TextInput from "clutch/src/TextInput/TextInput.jsx";
import type { DeepReadonly } from "ts-essentials";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
import MapImage from "@/game-eft/components/MapImage.jsx";
import { SelectMode } from "@/game-eft/components/Selects.jsx";
import { PROFILE_MAP_STAT_COLUMNS } from "@/game-eft/constants/statColumns.mjs";
import type { PlayerMapStats } from "@/game-eft/models/graphql.mjs";
import getMapName from "@/game-eft/utils/get-map-name.mjs";
import useMapImages from "@/game-eft/utils/use-map-images.mjs";
import usePlayerMapStats from "@/game-eft/utils/use-player-map-stats.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import Container from "@/shared/ContentContainer.jsx";
import DataTable from "@/shared/DataTable.jsx";
import SortOrder from "@/shared/SortOrder.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
// import HighlightStatCard from "@/shared-fps/HighlightStatCard.jsx";
// import TimescaleChart from "@/shared-fps/TimescaleChart.jsx";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { formatToPercent, getLocale } from "@/util/i18n-helper.mjs";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ONE_HOUR = 1000 * 60 * 60;
const Styled = {
  dataTable: () => css`
    & a {
      color: var(--shade0);
      font-weight: 725;
    }
    & .item-link {
      display: flex;
      align-items: center;
      gap: var(--sp-2);
    }
    & a > div {
      width: var(--sp-18);
      height: var(--sp-7);
      display: inline-table;
    }
    ${mobile} {
      & a.item-link > span {
        max-width: var(--sp-30);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `,
  highlights: () => css`
    display: flex;
    gap: var(--sp-4);
    & > section {
      flex-grow: 1;
    }
    & > section > .card-frame {
      height: 180px;
    }
    & .stat-title {
      color: #7f838b;
      font-size: 0.813rem;
      line-height: var(--sp-5);
      letter-spacing: -0.009rem;
      color: #e3e5ea80;
      font-weight: 625;
    }
  `,
};

// const TooltipTitle = styled("div")`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `;

const sortOptions = PROFILE_MAP_STAT_COLUMNS.map((stat) => ({
  text: stat.display,
  value: stat.key,
}));

export default function Main() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const state = useSnapshot(readState);
  const {
    parameters: [profileId],
  } = useRoute();
  const [searchFilter, setSearchFilter] = useQuery<string>("search", "");
  const [sortBy, setSortBy] = useQuery<string>(
    "sort",
    PROFILE_MAP_STAT_COLUMNS[0].key,
  );
  const [sortOrder, setSortOrder] = useQuery<"ASC" | "DESC">("order", "DESC");
  const { getMapImage } = useMapImages();
  const $playerMapStats = state.eft.playerMapStat[profileId];
  const playerMapStatsRaw: DeepReadonly<PlayerMapStats | undefined> =
    useEvalState($playerMapStats);
  const playerMapStatsMerged = usePlayerMapStats(playerMapStatsRaw, t);
  const playerMapStats = useMemo(() => {
    return playerMapStatsMerged.map((stats) => {
      const survivalRate = stats.survived / stats.count;
      const result = {
        ...stats,
        mapImage: getMapImage(stats.gameMap.key),
        survivalRate,
      };
      for (const key in result) {
        if (typeof result[key] !== "number") continue;
        result[key] = sanitizeNumber(result[key]);
      }
      return result;
    });
  }, [getMapImage, playerMapStatsMerged]);

  const { rows } = useMemo(() => {
    if (!playerMapStats) {
      return {
        bestMapStats: null,
        rows: [],
      };
    }
    const bestMapStats = [...playerMapStats].sort(
      (a, b) => b.count - a.count,
    )[0];
    const rows = playerMapStats
      .filter((stats) => {
        return t(...getMapName(stats.gameMap.key))
          .toLowerCase()
          .includes(searchFilter.toLowerCase());
      })
      .map((stats, i) => {
        return [
          {
            value: i,
            display: (
              <a className="item-link" href="#">
                <MapImage $url={stats.mapImage} />
                <span>{t(...getMapName(stats.gameMap.key))}</span>
              </a>
            ),
          },
          {
            value: stats.count,
            display: stats.count.toLocaleString(getLocale()),
          },
          {
            value: stats.survivalRate,
            display: formatToPercent(language, stats.survivalRate),
          },
          {
            value: stats.duration,
            display:
              stats.duration > ONE_HOUR
                ? t("lol:hours", "{{hours}} Hours", {
                    hours: formatDuration(stats.duration, "h:mm:ss"),
                  })
                : t("common:minutes", "{{minutes}} mins", {
                    minutes: formatDuration(stats.duration, "m:ss"),
                  }),
          },
          {
            value: stats.kills,
            display: stats.kills.toLocaleString(getLocale()),
          },
        ];
      });
    return {
      rows,
      bestMapStats,
    };
  }, [language, playerMapStats, searchFilter, t]);

  // const timeScaleData = useMemo(() => {
  //   return playerMapStats?.map((stats, i) => {
  //     return {
  //       key: stats.gameMap.key,
  //       tooltipTitle: (
  //         <TooltipTitle>{t(...getMapName(stats.gameMap.key))}</TooltipTitle>
  //       ),
  //       stats: {
  //         x: {
  //           value: i,
  //           display: `${i}`,
  //         },
  //         count: {
  //           value: stats.count,
  //           display: stats.count.toLocaleString(getLocale()),
  //         },
  //         survivalRate: {
  //           value: stats.survivalRate,
  //           display: formatToPercent(language, stats.survivalRate),
  //         },
  //         survivalTime: {
  //           value: stats.duration,
  //           display:
  //             stats.duration > ONE_HOUR
  //               ? t("lol:hours", "{{hours}} Hours", {
  //                   hours: formatDuration(stats.duration, "h:mm:ss"),
  //                 })
  //               : t("common:minutes", "{{minutes}} mins", {
  //                   minutes: formatDuration(stats.duration, "m:ss"),
  //                 }),
  //         },
  //       },
  //     };
  //   });
  // }, [playerMapStats, language, t]);

  if (!playerMapStats) return null;

  return (
    <Container className="flex column gap-sp-4">
      <div className="flex gap-2">
        <TextInput
          placeholder={t("common:search", "Search")}
          onChange={(e) => setSearchFilter(e.target.value)}
          value={searchFilter}
          Icon={SearchIcon}
        />
        <SelectMode />
        <div className="flex justify-end flex-grow gap-sp-2">
          <Select
            options={sortOptions}
            selected={sortBy || ""}
            onChange={(v) => setSortBy(v || undefined)}
          />
          <SortOrder sortOrder={sortOrder} setSortOrder={setSortOrder} />
        </div>
      </div>
      {/* <div className={Styled.highlights()}>
        <HighlightStatCard
          image={bestMapStats.mapImage}
          label={t(...getMapName(bestMapStats.gameMap.key))}
          stat={bestMapStats.count}
          statLabel="Raids"
          imageSize={320}
        />
        <TimescaleChart
          label={PROFILE_MAP_STAT_COLUMNS[1].display}
          position={4}
          stat1Label={PROFILE_MAP_STAT_COLUMNS[1].shortDisplay}
          stat2Label={PROFILE_MAP_STAT_COLUMNS[0].shortDisplay}
          color="var(--green)"
          id={PROFILE_MAP_STAT_COLUMNS[0].key}
          data={timeScaleData}
          xField="x"
          yField={PROFILE_MAP_STAT_COLUMNS[1].key}
          y2Field={PROFILE_MAP_STAT_COLUMNS[0].key}
          hidePosition
        />
        <TimescaleChart
          label={PROFILE_MAP_STAT_COLUMNS[2].display}
          position={2}
          stat1Label={PROFILE_MAP_STAT_COLUMNS[2].shortDisplay}
          stat2Label={PROFILE_MAP_STAT_COLUMNS[0].shortDisplay}
          color="var(--yellow)"
          id={PROFILE_MAP_STAT_COLUMNS[0].key}
          data={timeScaleData}
          xField="x"
          yField={PROFILE_MAP_STAT_COLUMNS[2].key}
          y2Field={PROFILE_MAP_STAT_COLUMNS[0].key}
          hidePosition
        />
      </div> */}
      <DataTable
        className={Styled.dataTable()}
        indexCol
        indexColTitle="#"
        cols={[
          {
            display: t("eft:map", "Map"),
            align: "left",
            primary: true,
          },
          ...PROFILE_MAP_STAT_COLUMNS.map((stat) => ({
            ...stat,
            display: t(...stat.display),
          })),
        ]}
        rows={rows}
      />
    </Container>
  );
}
