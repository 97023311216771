import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import WeaponImage from "@/game-eft/components/WeaponImage.jsx";
import { IS_ALIVE } from "@/game-eft/constants/constants.mjs";
import { aggregateHits } from "@/game-eft/utils/get-total-shots.mjs";
import useMatches from "@/game-eft/utils/use-matches.mjs";
import useRecentAvgs from "@/game-eft/utils/use-recent-avgs.mjs";
import useWeaponImages from "@/game-eft/utils/use-weapon-images.mjs";
import { getSRColor } from "@/game-eft/utils/utils.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { formatToPercent } from "@/util/i18n-helper.mjs";

const MAX_WEAPONS = 3;

export default function MatchHistoryHeader({
  profileId,
}: {
  profileId: string;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const matches = useMatches(profileId);
  const { getWeaponImage } = useWeaponImages();
  // Calcs
  const { recentAvgs } = useRecentAvgs(profileId);
  const total2 = Object.values(recentAvgs?.weaponHits ?? {}).reduce(
    (acc, cur) => acc + cur.hits,
    0,
  );
  const total3 = aggregateHits(recentAvgs);
  const totalShots = Math.max(
    ...[recentAvgs?.totalShots, total2, total3].map(sanitizeNumber),
  );
  const shotsLanded = Math.max(
    ...[recentAvgs?.shotsLanded, total2].map(sanitizeNumber),
  );
  const accuracy = sanitizeNumber(shotsLanded / totalShots);
  const survivalRate = sanitizeNumber(
    matches.reduce((acc, match) => {
      const player = match.playerMatches.find(
        (i) => i.player.accountId === profileId,
      );
      if (!IS_ALIVE.has(player?.exitReason)) return acc;
      return acc + 1;
    }, 0) / matches.length,
  );
  const weapons: {
    [weaponId: string]: Weapon;
  } = matches.reduce((acc, cur) => {
    const player = cur.playerMatches.find(
      (i) => i.player.accountId === profileId,
    );
    if (!player) return acc;
    // Player shot data
    for (const data of player.weaponHits) {
      acc[data.weapon.id] = acc[data.weapon.id] ?? {
        damage: 0,
        kills: 0,
        weapon: data.weapon,
      };
      acc[data.weapon.id].damage += data.damage;
      acc[data.weapon.id].kills += data.kills;
    }
    return acc;
  }, {});
  const lastNWeaponStats = Object.values(weapons)
    .concat(
      new Array(MAX_WEAPONS).fill(undefined).map(
        (_, idx) =>
          ({
            kills: 0,
            damage: 0,
            weapon: {
              id: btoa(String(MAX_WEAPONS * (idx + 1))),
            },
          }) as Weapon,
      ),
    )
    .sort((a, b) => b.damage - a.damage)
    .slice(0, MAX_WEAPONS);
  return (
    <Card className="flex gap-5 align-center justify-between">
      <div className="flex gap-5 align-center">
        <div>
          <div className="type-caption color-shade2">
            {t("common:stats.recentnGames", "Recent {{numberOfGames}} Games", {
              numberOfGames: matches.length,
            })}
          </div>
          <div
            style={{ color: getSRColor(survivalRate * 100) }}
            className="type-subtitle--bold color-shade0"
          >
            {t("eft:srPercentile", "{{sr}} S/R", {
              sr: formatToPercent(language, survivalRate),
            })}
          </div>
        </div>
        <div>
          <div className="type-caption color-shade2">
            {t("common:stats.accuracy", "Accuracy")}
          </div>
          <div className="type-subtitle--bold color-shade0">
            {formatToPercent(language, accuracy)}
          </div>
        </div>
      </div>
      <div className="flex gap-5 align-center">
        {lastNWeaponStats.map((i) => (
          <div key={i.weapon.id} className="flex column align-center gap-2">
            <div
              data-tip={i.weapon?.longName}
              style={{
                width: "var(--sp-8)",
                height: "var(--sp-8)",
              }}
              className="flex align-center justify-center br"
            >
              <WeaponImage src={getWeaponImage(i.weapon.longName)} />
            </div>
            <div className="type-caption--semi color-shade1">
              {t("apex:stats.damageNumber", "{{damage_done, number}} Damage", {
                damage_done: sanitizeNumber(i.damage).toFixed(1),
              })}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}

type Weapon = {
  damage: number;
  kills: number;
  weapon: {
    id: string;
    longName: string;
    shortName: string;
    uuid: string;
  };
};
