import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import ProfileStatsCard, {
  WeaponContainer,
} from "@/game-eft/components/ProfileStatsCard.jsx";
import WeaponImage from "@/game-eft/components/WeaponImage.jsx";
import { GameMode, SearchParamsEnum } from "@/game-eft/constants/constants.mjs";
import useMatches from "@/game-eft/utils/use-matches.mjs";
import useWeaponImages from "@/game-eft/utils/use-weapon-images.mjs";
import ChevronRightIcon from "@/inline-assets/chevron-right.svg";
import type { SORT_DIR } from "@/shared/DataTable.jsx";
import { DataTableExtSort as DataTable } from "@/shared/DataTable.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { formatToPercent } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MAX_WEAPONS = 5;

export default function ProfileStatsWeapons({
  profileId,
}: {
  profileId: string;
}) {
  const { t } = useTranslation();
  const { searchParams } = useRoute();
  const state = useSnapshot(readState);
  const { getWeaponImage } = useWeaponImages();
  const matches = useMatches(profileId, undefined, true);
  const [sortDir, setSortDir] = useState<SORT_DIR>("DESC");
  const [sortCol, setSortCol] = useState(1);
  // Constants
  const mode = searchParams.get(SearchParamsEnum.GameMode) ?? GameMode.DEFAULT;
  // Raw state
  const $playerWeaponStats = state.eft.playerWeaponStat[profileId]?.[mode];
  // Eval state
  const playerWeaponStats = useEvalState($playerWeaponStats);
  const weapons = useMemo(
    () => playerWeaponStats?.slice().sort((a, b) => b.kills - a.kills) ?? [],
    [playerWeaponStats],
  );
  const rows = useMemo(() => {
    if (!weapons.length) return [];
    return weapons.slice(0, MAX_WEAPONS).map((i) => {
      return [
        {
          display: (
            <WeaponContainer>
              <WeaponImage src={getWeaponImage(i.weapon.longName)} />
              <p>{i.weapon.shortName}</p>
            </WeaponContainer>
          ),
          value: i.weapon.shortName,
        },
        {
          display: Math.round(i.kills),
          value: i.kills,
        },
      ];
    });
  }, [getWeaponImage, weapons]);
  const weaponUsage = useMemo(() => {
    let count = 0;
    const topWeaponIds = weapons.reduce((acc, cur) => {
      acc[cur.weapon.uuid] = true;
      return acc;
    }, Object.create(null));
    for (const match of matches) {
      if (
        !match.playerMatches
          .find((i) => i.player.accountId === profileId)
          ?.weaponHits.find((i) => topWeaponIds[i.weapon.uuid])
      )
        continue;
      count += 1;
    }
    return formatToPercent(undefined, sanitizeNumber(count / matches.length));
  }, [matches, profileId, weapons]);
  if (!rows.length) return null;
  const cols = [
    {
      display: t("common:weapon", "Weapon"),
      align: "left",
      primary: true,
      key: "weapon",
    },
    {
      display: t("common:stats.kills", "Kills"),
      align: "right",
      key: "kills",
      isStat: true,
    },
  ];
  return (
    <ProfileStatsCard
      header={
        <a
          href={`/eft/profile/${profileId}/weapons`}
          className="flex align-center gap-2 type-title--bold"
        >
          <span>{t("common:weapons", "Weapons")}</span>
          <ChevronRightIcon />
        </a>
      }
      footer={
        <Trans i18nKey="eft:match.stats.weaponRaids">
          These {{ length: rows.length }} weapons comprise{" "}
          <span style={{ color: "var(--blue)" }}>{weaponUsage}</span> of this
          player&apos;s raids.
        </Trans>
      }
    >
      <DataTable
        cols={cols}
        rows={rows}
        sortDir={sortDir}
        sortCol={sortCol}
        sortColTiebreak={3}
        indexCol={false}
        searchCol={0}
        setSelectedSortCol={setSortCol}
        setSelectedSortDir={setSortDir}
      />
    </ProfileStatsCard>
  );
}
