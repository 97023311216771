import { useMemo } from "react";
import type { TFunction } from "i18next";
import type { DeepReadonly } from "ts-essentials";

import type { PlayerMapStats } from "@/game-eft/models/graphql.mjs";
import getMapName from "@/game-eft/utils/get-map-name.mjs";

export default function usePlayerMapStats(
  playerMapStats: DeepReadonly<PlayerMapStats> | PlayerMapStats | undefined,
  t: TFunction,
): PlayerMapStats {
  const newMap = t(...getMapName(""));
  const playerMapStatsMerged = useMemo<PlayerMapStats>(
    () =>
      Array.isArray(playerMapStats)
        ? (Object.values(
            // For some reason, without this `as PlayerMapStats`, reduce won't accept a type argument.
            // This cast makes TS happy and allows us to specify the proper type argument to reduce to
            // keep things from getting incorrectly typed.
            (playerMapStats as PlayerMapStats).reduce<
              Record<string, PlayerMapStats[number]>
            >((acc, cur) => {
              const mapName = t(...getMapName(cur.gameMap.key));
              if (mapName === newMap) return acc;
              if (acc[mapName]) {
                for (const key in cur) {
                  const value = cur[key];
                  if (typeof value !== "number") continue;
                  acc[mapName][key] += value;
                }
              } else {
                acc[mapName] = cur;
              }
              return acc;
            }, {}),
          ) as PlayerMapStats)
        : [],
    [playerMapStats],
  );
  return playerMapStatsMerged;
}
