import React from "react";
import { useTranslation } from "react-i18next";

import { formatDuration } from "@/app/util.mjs";
import WeaponImage from "@/game-eft/components/WeaponImage.jsx";
import {
  MatchTileTabsEnum,
  PROFILE_MATCHTILE_TABS,
} from "@/game-eft/constants/constants.mjs";
import type { Match } from "@/game-eft/models/graphql.mjs";
import getDamage from "@/game-eft/utils/get-damage.mjs";
import getMapName from "@/game-eft/utils/get-map-name.mjs";
import getRaidStatus from "@/game-eft/utils/get-raid-status.mjs";
import getTotalShots from "@/game-eft/utils/get-total-shots.mjs";
import useMapImages from "@/game-eft/utils/use-map-images.mjs";
import useMatches from "@/game-eft/utils/use-matches.mjs";
import useWeaponImages from "@/game-eft/utils/use-weapon-images.mjs";
import getDataTablePills from "@/shared/get-data-table-pills.mjs";
import MatchTileExpand from "@/shared/MatchTileExpand.jsx";
import globals from "@/util/global-whitelist.mjs";
import { sanitizeNumber, toFixedNumber } from "@/util/helpers.mjs";
import { formatToPercent, getLocaleString } from "@/util/i18n-helper.mjs";

const Tabs = {
  [MatchTileTabsEnum.Scoreboard]: () =>
    import("@/game-eft/components/MatchTileScoreboard.jsx"),
  [MatchTileTabsEnum.Statistics]: () =>
    import("@/game-eft/components/MatchTileStatistics.jsx"),
  [MatchTileTabsEnum.Weapons]: () =>
    import("@/game-eft/components/MatchTileWeapons.jsx"),
  [MatchTileTabsEnum.Hits]: () =>
    import("@/game-eft/components/MatchTileHits.jsx"),
};

export default function MatchListRow({
  profileId,
  match,
}: {
  profileId: string;
  match: Match;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { getMapImage } = useMapImages();
  const { getWeaponImage } = useWeaponImages();
  const matches = useMatches(profileId);
  const player = match.playerMatches.find(
    (i) => i.player.accountId === profileId,
  );
  const { killsHist, damageHist, accuracyHist } = matches.reduce(
    (acc, cur, idx, arr) => {
      const player = cur.playerMatches.find(
        (i) => i.player.accountId === profileId,
      );
      const { totalShots, shotsLanded } = getTotalShots(profileId, cur);
      acc.killsHist += sanitizeNumber(player?.killsBody + player?.killsHead);
      acc.damageHist += getDamage(profileId, cur);
      acc.accuracyHist += sanitizeNumber(shotsLanded / totalShots);
      const length = arr.length;
      if (length - 1 === idx)
        for (const key in acc) acc[key] = sanitizeNumber(acc[key] / length);
      return acc;
    },
    { killsHist: 0, damageHist: 0, accuracyHist: 0 },
  );
  // Constants
  const { isWin, translation } = getRaidStatus(player?.exitReason);
  const raidStatus = t(...translation);
  const kills = sanitizeNumber(player?.killsBody + player?.killsHead);
  const damage = getDamage(profileId, match);
  const { shotsLanded, totalShots } = getTotalShots(profileId, match);
  const accuracy = sanitizeNumber(shotsLanded / totalShots);
  const bestWeapon = Object.values(player?.weaponHits ?? {}).sort(
    (a, b) => b.kills - a.kills,
  )[0];

  return (
    <MatchTileExpand
      isWin={isWin}
      image={{
        src: getMapImage(match.gameMap.key),
        alt: match.gameMap.key,
      }}
      titles={[
        {
          text: raidStatus,
          bold: true,
          color: isWin ? "var(--turq)" : "var(--red)",
        },
        {
          text: getMapName(match.gameMap.key),
        },
        player?.faction && {
          text: player.faction,
        },
        player?.duration && {
          text: formatDuration(player.duration, "mm:ss"),
        },
      ].filter(Boolean)}
      tabDefault={MatchTileTabsEnum.Scoreboard}
      tabsToElement={Tabs}
      tabsLabel={PROFILE_MATCHTILE_TABS}
      tabArgs={{
        gameId: match.gameId,
        profileId,
      }}
      stats={[
        {
          top: (
            <span>
              {t("common:stats.killsCount", "{{count, number}} Kills", {
                count: kills,
              })}
            </span>
          ),
          pill: getDataTablePills(kills, [killsHist]),
          bottom: t("common:stats.kills", "Kills"),
        },
        {
          top: getLocaleString(toFixedNumber(damage, 1)),
          pill: getDataTablePills(damage, [damageHist]),
          bottom: t("common:stats.damage", "Damage"),
        },
        {
          top: formatToPercent(language, accuracy),
          pill: getDataTablePills(accuracy, [accuracyHist]),
          bottom: t("common:accuracy", "Accuracy"),
        },
      ]}
      urlWeb={`${globals.location.origin}/eft/match/${profileId}/${match.gameId}`}
      urlCanonical={`/eft/match/${profileId}/${match.gameId}`}
      aside={
        <>
          <WeaponImage
            src={getWeaponImage(bestWeapon?.weapon.longName)}
            width="96"
            height="30"
            style={{ width: "var(--sp-24)" }}
            data-tip={bestWeapon?.weapon.longName}
          />
          <div
            className="color-shade2 type-caption"
            style={{ textAlign: "center", paddingBlockStart: "var(--sp-2)" }}
          >
            {t("common:stats.killsCount", "{{count, number}} Kills", {
              count: sanitizeNumber(bestWeapon?.kills),
            })}
          </div>
        </>
      }
      matchDate={new Date(match.startedAt)}
    />
  );
}
