import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import ProfileStatsCard, {
  WeaponContainer,
} from "@/game-eft/components/ProfileStatsCard.jsx";
import WeaponImage from "@/game-eft/components/WeaponImage.jsx";
import getMapName from "@/game-eft/utils/get-map-name.mjs";
import useMapImages from "@/game-eft/utils/use-map-images.mjs";
import useMatches from "@/game-eft/utils/use-matches.mjs";
import usePlayerMapStats from "@/game-eft/utils/use-player-map-stats.mjs";
import ChevronRightIcon from "@/inline-assets/chevron-right.svg";
import InfoIcon from "@/inline-assets/info-button.svg";
import type { SORT_DIR } from "@/shared/DataTable.jsx";
import { DataTableExtSort as DataTable } from "@/shared/DataTable.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
import { displayRate, sanitizeNumber } from "@/util/helpers.mjs";
import { formatToPercent } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const MAX_MAPS = 3;

// Todo: Localization
// Upload localization to cdn: https://github.com/theblitzapp/game-data-processing/blob/main/eft/processed_data/_en_locale_processed.json
// Use item id to search for localization key

export default function ProfileStatsMaps({ profileId }: { profileId: string }) {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const { getMapImage } = useMapImages();
  const matches = useMatches(profileId, undefined, true);
  const [sortDir, setSortDir] = useState<SORT_DIR>("DESC");
  const [sortCol, setSortCol] = useState(1);
  // Raw state
  const $playerMapStats = state.eft.playerMapStat[profileId];
  // Eval state
  const playerMapStatsRaw = useEvalState($playerMapStats);
  const playerMapStats = usePlayerMapStats(playerMapStatsRaw, t);
  const maps = useMemo(
    () => playerMapStats?.slice().sort((a, b) => b.survived - a.survived) ?? [],
    [playerMapStats],
  );
  const mapUsage = useMemo(() => {
    let count = 0;
    const topMaps = maps.reduce((acc, cur) => {
      acc[cur.gameMap.key] = true;
      return acc;
    }, Object.create(null));
    for (const match of matches) {
      if (!topMaps[match.gameMap.key]) continue;
      count += 1;
    }
    return formatToPercent(undefined, sanitizeNumber(count / matches.length));
  }, [maps, matches]);
  const rows = useMemo(() => {
    if (!maps.length) return [];
    return maps.slice(0, MAX_MAPS).map((i) => {
      const mapName = t(...getMapName(i.gameMap.key));
      return [
        {
          display: (
            <WeaponContainer>
              <WeaponImage src={getMapImage(i.gameMap.key)} />
              <p>{mapName}</p>
            </WeaponContainer>
          ),
          value: mapName,
        },
        {
          display: displayRate(i.shotsLanded, i.totalShots),
          value: sanitizeNumber(i.shotsLanded / i.totalShots),
        },
        {
          display: displayRate(i.survived, i.count),
          value: sanitizeNumber(i.survived / i.count),
        },
      ];
    });
  }, [getMapImage, maps, t]);
  if (!rows.length) return null;
  const cols = [
    {
      display: t("common:map", "Map"),
      align: "left",
      primary: true,
      key: "map",
    },
    {
      display: t("common:accuracy", "Accuracy"),
      align: "right",
      key: "accuracy",
      isStat: true,
    },
    {
      display: (
        <>
          <InfoIcon style={{ left: "calc(-1 * var(--sp-5))" }} />
          <span>{t("eft:sr", "S/R")}</span>
        </>
      ),
      tooltip: t("eft:survivalRate", "Survival Rate"),
      align: "right",
      key: "survivalRate",
      isStat: true,
    },
  ];
  return (
    <ProfileStatsCard
      header={
        <a
          href={`/eft/profile/${profileId}/maps`}
          className="flex align-center gap-2 type-title--bold"
        >
          <span>{t("common:maps", "Maps")}</span>
          <ChevronRightIcon />
        </a>
      }
      footer={
        <Trans i18nKey="eft:match.stats.mapRaids">
          These {{ length: rows.length }} maps comprise{" "}
          <span style={{ color: "var(--blue)" }}>{mapUsage}</span> of this
          player&apos;s raids.
        </Trans>
      }
    >
      <DataTable
        cols={cols}
        rows={rows}
        sortDir={sortDir}
        sortCol={sortCol}
        sortColTiebreak={3}
        indexCol={false}
        searchCol={0}
        setSelectedSortCol={setSortCol}
        setSelectedSortDir={setSortDir}
        sortable={false}
      />
    </ProfileStatsCard>
  );
}
