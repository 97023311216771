import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

import type { PlayerFactionStat } from "@/game-eft/models/graphql.mjs";
import { getFactionIcon } from "@/game-eft/utils/get-faction-icon.mjs";
import useFactions from "@/game-eft/utils/use-factions.mjs";
import Ripple from "@/shared/Ripple.jsx";
import { getLocaleString } from "@/util/i18n-helper.mjs";

const MAX_ITEMS = 2;

export default function FactionAccordion({ profileId }: { profileId: string }) {
  const playerFactionStats = useFactions(profileId);
  if (!playerFactionStats.length) return null;
  const stats = playerFactionStats.slice(0, MAX_ITEMS);
  return (
    <Card padding={0}>
      {stats.map((i, idx) => (
        <FactionStat key={i.faction} {...i} index={idx} />
      ))}
    </Card>
  );
}

export function FactionStat(stat: PlayerFactionStat & { index: number }) {
  const { t } = useTranslation();
  const [isExpanded, setExpanded] = React.useState(!stat.index);
  const toggle = () => setExpanded((prev) => !prev);
  const FactionIcon = getFactionIcon(stat.faction);
  const Icon = React.createElement(FactionIcon ?? "div", {
    style: { width: "var(--sp-4)", height: "var(--sp-4)" },
  });
  return (
    <FactionStatContainer onClick={toggle} $isExpanded={isExpanded}>
      <Ripple />
      {isExpanded ? (
        <div className="flex gap-3 column">
          <div>
            <div></div>
            <div className="flex gap-2 align-center type-callout--semi shade0">
              {Icon}
              <span>{stat.faction}</span>
            </div>
          </div>
          <div className="flex gap-2 column">
            <div className="flex justify-between">
              <div className="type-caption--bold shade2">
                {t("eft:stats.raids", "{{raids}} Raids", {
                  raids: getLocaleString(stat.matches),
                })}
              </div>
            </div>{" "}
            <div className="flex justify-between">
              <div className="type-caption shade2">
                {t("tft:levelValue", "Level {{level}}", {
                  level: getLocaleString(stat.level),
                })}
              </div>
              <div className="type-caption shade2">
                {t("eft:stats.experience", "{{num}} experience", {
                  num: getLocaleString(stat.experience),
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div>
            <div className="flex gap-2 align-center type-callout--semi shade1">
              {Icon}
              <span>{stat.faction}</span>
            </div>
          </div>
          <div
            className="type-callout--bold shade2"
            style={{ textAlign: "right" }}
          >
            {t("eft:stats.raids", "{{raids}} Raids", {
              raids: getLocaleString(stat.matches),
            })}
          </div>
        </>
      )}
    </FactionStatContainer>
  );
}

const FactionStatContainer = styled<{
  $isExpanded: boolean;
  onClick: () => void;
}>("div")`
  position: relative;
  ${({ $isExpanded }) => {
    return $isExpanded
      ? `display: block;`
      : `display: flex;
    justify-content: space-between;
    gap: var(--sp-3);`;
  }}
  padding: var(--sp-3) var(--sp-6);
  &:hover {
    background-color: var(--shade7);
  }
  background-color: ${({ $isExpanded }) =>
    $isExpanded ? "var(--shade7)" : "transparent"};
`;
