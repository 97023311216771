import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Select } from "clutch/src/Select/Select.jsx";
import { mobile } from "clutch/src/Style/style.mjs";
import TextInput from "clutch/src/TextInput/TextInput.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { formatDuration } from "@/app/util.mjs";
import { SelectMode } from "@/game-eft/components/Selects.jsx";
import { GameMode, SearchParamsEnum } from "@/game-eft/constants/constants.mjs";
import { PROFILE_WEAPON_STAT_COLUMNS } from "@/game-eft/constants/statColumns.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import Container from "@/shared/ContentContainer.jsx";
import { DataTableExtSort as DataTable } from "@/shared/DataTable.jsx";
import SortOrder from "@/shared/SortOrder.jsx";
import { useEvalState } from "@/util/eval-state.mjs";
// import HighlightStatCard from "@/shared-fps/HighlightStatCard.jsx";
// import TimescaleChart from "@/shared-fps/TimescaleChart.jsx";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { formatToPercent, getLocale } from "@/util/i18n-helper.mjs";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ONE_HOUR = 1000 * 60 * 60;
const Styled = {
  dataTable: () => css`
    & a {
      color: var(--shade0);
      font-weight: 725;
    }
    & .item-link {
      display: flex;
      align-items: center;
      gap: var(--sp-2);
    }
    & a > div {
      width: var(--sp-18);
      height: var(--sp-7);
      display: inline-table;
    }

    ${mobile} {
      & a.item-link > span {
        max-width: var(--sp-30);
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `,
  highlights: () => css`
    display: flex;
    gap: var(--sp-4);

    & > section {
      flex-grow: 1;
    }

    & > section > .card-frame {
      height: 180px;
    }

    & .stat-title {
      color: #7f838b;
      font-size: 0.813rem;
      line-height: var(--sp-5);
      letter-spacing: -0.009rem;
      color: #e3e5ea80;
      font-weight: 625;
    }
  `,
};

// const TooltipTitle = styled("div")`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
// `;

const sortOptions = PROFILE_WEAPON_STAT_COLUMNS.map((stat) => ({
  text: stat.display,
  value: stat.key,
}));

export default function Main() {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const state = useSnapshot(readState);
  const {
    parameters: [profileId],
    searchParams,
  } = useRoute();
  const gameMode =
    searchParams.get(SearchParamsEnum.GameMode) ?? GameMode.DEFAULT;
  const [searchFilter, setSearchFilter] = useQuery<string>("search", "");
  const [sortBy, setSortBy] = useQuery<string>(
    "sort",
    PROFILE_WEAPON_STAT_COLUMNS[0].key,
  );
  const [sortOrder, setSortOrder] = useQuery<"ASC" | "DESC">("order", "DESC");
  const $playerWeaponStats = state.eft.playerWeaponStat[profileId]?.[gameMode];
  const playerWeaponStatsRaw = useEvalState($playerWeaponStats);
  const playerWeaponStats = useMemo(() => {
    return playerWeaponStatsRaw?.map((stats) => {
      const accuracy = stats.shotsLanded / stats.totalShots;
      const timePlayed = stats.duration / 1000 / 60 / 60; // ms -> sec -> min -> hour
      const hsPercentage = stats.hitsHead / stats.totalShots;
      const killsRaid = stats.kills / stats.count;
      const result = {
        ...stats,
        accuracy,
        timePlayed,
        headshotPercentage: hsPercentage,
        killsRaid,
      };
      for (const key in result) {
        if (typeof result[key] !== "number") continue;
        result[key] = sanitizeNumber(result[key]);
        if (result[key] < 0) result[key] = 0; // Prevent negative values
      }
      return result;
    });
  }, [playerWeaponStatsRaw]);

  const { rows } = useMemo(() => {
    if (!playerWeaponStats) {
      return {
        bestWeaponStats: null,
        rows: [],
      };
    }
    const bestWeaponStats = [...playerWeaponStats].sort(
      (a, b) => b.kills - a.kills,
    )[0];
    const rows = playerWeaponStats
      .filter((stats) => {
        return stats.weapon.longName
          .toLowerCase()
          .includes(searchFilter.toLowerCase());
      })
      .map((stats) => {
        return [
          {
            value: stats.weapon.longName,
            display: (
              <a
                className="item-link"
                href={`/eft/stats/weapons?${SearchParamsEnum.WeaponName}=${stats.weapon.longName}`}
              >
                {stats.weapon.longName}
              </a>
            ),
          },
          {
            value: stats.kills,
            display: stats.kills.toLocaleString(getLocale()),
          },
          {
            value: stats.killsRaid,
            display: stats.killsRaid.toFixed(1),
          },
          {
            value: stats.accuracy,
            display: formatToPercent(language, stats.accuracy),
          },
          {
            value: stats.headshotPercentage,
            display: formatToPercent(language, stats.headshotPercentage),
          },
          {
            value: stats.damageDealt / stats.count,
            display: (stats.damageDealt / stats.count).toFixed(1),
          },
          {
            value: stats.duration,
            display:
              stats.duration > ONE_HOUR
                ? t("lol:hours", "{{hours}} Hours", {
                    hours: formatDuration(stats.duration, "h:mm:ss"),
                  })
                : t("common:minutes", "{{minutes}} mins", {
                    minutes: formatDuration(stats.duration, "m:ss"),
                  }),
          },
        ];
      });
    return {
      rows,
      bestWeaponStats,
    };
  }, [language, playerWeaponStats, searchFilter, t]);

  // const timeScaleData = useMemo(() => {
  //   return playerWeaponStats?.map((stats, i) => {
  //     const hsPercentage = sanitizeNumber(stats.hitsHead / stats.totalShots);
  //     const killsRaid = sanitizeNumber(stats.kills / stats.count);
  //     return {
  //       key: stats.weapon.id,
  //       tooltipTitle: <TooltipTitle>{stats.weapon.longName}</TooltipTitle>,
  //       stats: {
  //         x: {
  //           value: i,
  //           display: `${i}`,
  //         },
  //         kills: {
  //           value: stats.kills,
  //           display: stats.kills.toLocaleString(getLocale()),
  //         },
  //         killsRaid: {
  //           value: killsRaid,
  //           display: killsRaid.toFixed(1),
  //         },
  //         headshotPercentage: {
  //           value: hsPercentage * 100,
  //           display: formatToPercent(language, hsPercentage),
  //         },
  //       },
  //     };
  //   });
  // }, [playerWeaponStats, language]);

  if (!playerWeaponStats) return null;

  return (
    <Container className="flex column gap-sp-4">
      <div className="flex gap-2">
        <TextInput
          placeholder={t("common:search", "Search")}
          onChange={(e) => setSearchFilter(e.target.value)}
          value={searchFilter}
          Icon={SearchIcon}
        />
        <SelectMode />
        <div className="flex justify-end flex-grow gap-sp-2">
          <Select
            options={sortOptions}
            selected={sortBy || ""}
            onChange={(v) => setSortBy(v || undefined)}
          />
          <SortOrder sortOrder={sortOrder} setSortOrder={setSortOrder} />
        </div>
      </div>
      {/* <div className={Styled.highlights()}>
        <HighlightStatCard
          image={getWeaponImage()}
          label={bestWeaponStats.weapon.longName}
          stat={bestWeaponStats.kills.toLocaleString(getLocale())}
          statLabel="Kills"
          imageSize={220}
        />
        <TimescaleChart
          label={PROFILE_WEAPON_STAT_COLUMNS[1].display}
          position={4}
          stat1Label={PROFILE_WEAPON_STAT_COLUMNS[1].shortDisplay}
          stat2Label={PROFILE_WEAPON_STAT_COLUMNS[0].shortDisplay}
          color="var(--orange)"
          id={PROFILE_WEAPON_STAT_COLUMNS[0].key}
          data={timeScaleData}
          xField="x"
          yField={PROFILE_WEAPON_STAT_COLUMNS[1].key}
          y2Field={PROFILE_WEAPON_STAT_COLUMNS[0].key}
          hidePosition
        />
        <TimescaleChart
          label={PROFILE_WEAPON_STAT_COLUMNS[3].display}
          position={2}
          stat1Label={PROFILE_WEAPON_STAT_COLUMNS[3].shortDisplay}
          stat2Label={PROFILE_WEAPON_STAT_COLUMNS[0].shortDisplay}
          color="var(--blue)"
          id={PROFILE_WEAPON_STAT_COLUMNS[0].key}
          data={timeScaleData}
          xField="x"
          yField={PROFILE_WEAPON_STAT_COLUMNS[3].key}
          y2Field={PROFILE_WEAPON_STAT_COLUMNS[0].key}
          hidePosition
        />
      </div> */}
      <DataTable
        className={Styled.dataTable()}
        indexCol
        indexColTitle="#"
        cols={[
          {
            display: t("eft:weapon", "Weapon"),
            align: "left",
            primary: true,
          },
          ...PROFILE_WEAPON_STAT_COLUMNS.map((stat) => ({
            ...stat,
            display: t(...stat.display),
          })),
        ]}
        sortDir={sortOrder}
        sortCol={
          PROFILE_WEAPON_STAT_COLUMNS.findIndex(({ key }) => key === sortBy) + 1
        }
        sortColTiebreak={3}
        setSelectedSortCol={(colNum) =>
          setSortBy(PROFILE_WEAPON_STAT_COLUMNS[colNum - 1].key)
        }
        setSelectedSortDir={setSortOrder}
        rows={rows}
        searchCol={0}
      />
    </Container>
  );
}
